import {
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

import data from './techradar.json';

export class TechRadarClient implements TechRadarApi {
    async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
        // if needed id prop can be used to fetch the correct data


        // For example, this converts the timeline dates into date objects
        return {
            ...data,
            entries: data.entries.map(entry => ({
                ...entry,
                timeline: entry.timeline.map(timeline => ({
                    ...timeline,
                    date: new Date(timeline.date),
                })),
            })),
        };
    }
}
