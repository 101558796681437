import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const rtlTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#0C75ED',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#0C75ED',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#0C75ED',
      navigation: {
        background: '#000000',
        indicator: '#D4000D',
        color: '#ffffff',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'RTLUnitedText-Regular',
  typography: {
    htmlFontSize: 16,
    fontFamily: 'RTLUnitedText-Regular',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: {
    home: genPageTheme({ colors: ['#D4000D', '#D4000D'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#D4000D', '#D4000D'],
      shape: shapes.wave
    }),
    tool: genPageTheme({ colors: ['#D4000D', '#D4000D'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#D4000D', '#D4000D'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#D4000D', '#D4000D'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#D4000D', '#D4000D'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#D4000D', '#D4000D'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#D4000D', '#D4000D'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#D4000D', '#D4000D'], shape: shapes.wave }),
  },
});

